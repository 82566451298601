<template>
    <div class="modal-edit-style-type items-start bg-main">
        <!-- <div class="header" @click="submit">
            <i class="material-icons m-16">chevron_left</i>
        </div> -->
        <div class="header">
            <i class="material-icons m-16" @click="submit">chevron_left</i>
            <div v-if="$isAndroid()" @click="submit" class="edit-profile-complete m-r-4">저장</div>
        </div>
        <div class="question">
            <div class="title f-18 f-medium m-t-20 m-b-20 required">우선 가치</div>
            <div class="description c-grey-07 f-14 m-b-20">
                소개받을 시 외적인 요소(외모, 체형, 키)와 조건(직업, 경제력, 학업 등) 중 어떤 것에 더 가중치를 두시나요?
            </div>
            <div class="preference">
                <div class="selector m-r-14 m-l-14">
                    <div class="line" />
                    <div
                        class="circle"
                        :class="`circle-${i}`"
                        v-for="i in [1, 2, 3, 4, 5, 6]"
                        @click="options.style.priority = i"
                        :key="i"
                    >
                        <div class="inner-circle" v-if="options.style.priority === i" />
                    </div>
                </div>
                <div class="label m-t-6 m-l-4 m-r-20">
                    <div class="f-12 c-grey-07">외적인 요소</div>
                    <div class="f-12 c-grey-07">조건</div>
                </div>
            </div>
        </div>
        <div class="info-box m-t-30">
            <span class="info-box-text" v-html="priorityStringify" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalStylePriority',
    props: ['options'],
    data: () => ({
        item: null,
        // checkInfo: false,
    }),
    computed: {
        me() {
            return this.$store.getters.me
        },
        style() {
            return this.$store.getters.me.style
        },
        displayGrid() {
            return ['religion'].includes(this.options.key)
        },
        multiSelect() {
            return ['religion', 'university'].includes(this.options.key)
        },
        priorityStringify() {
            let value = ''
            if (this.style.priority === 6) {
                value = '조건이 훨씬 더 중요'
            } else if (this.style.priority === 5) {
                value = '조건이 더 중요'
            } else if (this.style.priority === 4) {
                value = '조건이 약간 더 중요'
            } else if (this.style.priority === 3) {
                value = '외적인 요소가 약간 더 중요'
            } else if (this.style.priority === 2) {
                value = '외적인 요소가 더 중요'
            } else if (this.style.priority === 1) {
                value = '외적인 요소가 훨씬 더 중요'
            }
            return value
        },
    },
    mounted() {
        // this.init()
    },
    methods: {
        // init() {
        //     const gender = this.me.gender === 0 ? 'male' : 'female'
        // },
        submit() {
            this.$emit('close', this.options.style.priority)
        },
    },
}
</script>
<style scoped lang="scss">
.modal-edit-style-type {
    overflow-y: hidden;
}
.header {
    position: sticky;
    max-height: 52px;
    top: 0;
    background: #faf0e1;
    display: flex;
    // justify-content: flex-start;
    justify-content: space-between;
    align-items: center;
    // padding-left: 16px;
    // padding: 16px;
}
.edit-profile-complete {
    padding: 12px 16px;
    color: #151360;
    font-weight: 600;
    font-size: 15px;
}
.title {
    font-size: 18px;
    // text-align: center;
    color: #151360;
}
.description {
    line-height: 24px;
}
.question {
    padding: 0 16px;

    .preference {
        .selector,
        .label {
            position: relative;
            @include between;
        }
        .circle {
            z-index: 2;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid $grey-04;
            background: white;
            position: relative;

            &.circle-2,
            &.circle-5 {
                width: 28px;
                height: 28px;
            }
            &.circle-3,
            &.circle-4 {
                width: 20px;
                height: 20px;
            }

            .inner-circle {
                width: 80%;
                height: 80%;
                border-radius: 50%;
                background: $blue-primary;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
        }
        .line {
            width: 100%;
            height: 1px;
            background: $grey-04;
            position: absolute;
            top: 50%;
        }
    }
}
.info-box {
    margin: 20px;
    @include center;
    height: 48px;
    // margin: 0 20px;
    background: #faf0e1;
    border-radius: 8px;
    color: #151360;
}
</style>
